import React, { useContext } from "react";
import { Navigate} from "react-router-dom";
import AuthContext from "../Hooks/userContext";

export default function PrivateRoute({ children }) {
  const { user, loading } = useContext(AuthContext);
  console.log("user", user);
  console.log("Component", children);

  if(loading) return <div>Loading...</div>;
  if (!user) return <Navigate to="/" />;
  if (!children) return <div>Component not found</div>;
  
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page

  return <>{children}</>;
}
