import React from 'react'
import { motion } from 'framer-motion';
import Table from 'react-bootstrap/Table'

export default function Step3({prevStep, values, tripData, submitPayment, handleUpload}) {

    const Previous = (e) =>{
        e.preventDefault();
        prevStep();
    }

    const changeHandler = (event) => {
		handleUpload(event.target.files);
	};

    const Payment = (e) =>{
        e.preventDefault();
        submitPayment();
    }

    return (
        <div>
            <section className="multi_step_form">
                <div className="msform">
                    <div className="tittle">
                    <h2>Pedido de Visto</h2>
                    </div>

                    <ul id="progressbar">
                        <li className="active">Dados Iniciais</li>
                        <li className="active">Visto</li>
                        <li className="active">Pagamento</li>
                    </ul>
                    <motion.div
                    className="col-md-6 offset-md-3"
                    initial={{ x: '-100vw' }}
                    animate={{ x: 0 }}
                    transition={{     type: 'tween',
                    duration: 0.7 }}>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nome</th>
                                    <th>Destino</th>
                                    <th>Tipo de Visto</th>
                                    <th>Valor</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                {
                                values.map((data,i) => {
                                    
                                    return (
                                        
                                        <tr>
                                            <td key={i}>{i+1}</td>
                                            <td key={i}>{data.nome}</td>
                                            <td key={i}>{tripData.pais}</td>
                                            <td key={i}>{tripData.tipo_visto}</td>
                                            <td key={i}>{tripData.preço}</td>
                                        </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <td className="bg-grey" colSpan={2}>Valor Total</td>
                                    <td colSpan={4}>{values.length*Number((tripData.preço).replace("€",""))+"€"}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <div className="row">
                                <div className="col-sm">
                                    <label>Documentos</label> 
                                    <br></br>
                                    <input type="file" name="file" multiple onChange={changeHandler}/>    
                                </div>
                        </div>
                    </motion.div>

                    <button type="button" className="action-button previous previous_button" onClick={Previous}>Voltar</button>
                    <button type="button" className="next action-button" onClick={Payment}>Concluir Pagamento</button>            
                </div>
            </section>
        </div>
    )
}
