import React from 'react'
import { motion } from 'framer-motion';
import { Form, Button } from 'react-bootstrap';


export default function Step2({nextStep, handleChange, handleNewfield, values}) {

    const val = [...values];
    const Continue = (e) =>{
        e.preventDefault();
        const isNull = Object.values(val[0]).every(value => {
            if (value.length === 0) {
                console.log("teste1")
                return false;
              
            }
            console.log(value)
            return true;
          });

        if(isNull === false){
            alert("Preencha todos os campos")
            return;
        }
        nextStep();
    }

    const handleAddFields = (e) => {
        e.preventDefault()
        val.push({            
        nome:"",
        apelido:"",
        email:"",
        telefone:"",
        nacionalidade:"",
        genero:"",
        data_nascimento:"",
        local_nascimento:"",
        data_viagem:"",
        data_saida:"",
        num_pass:"",
        data_emissao:"",
        data_validade:"",
        paises_visitados:"",
        visto_pais:"",
        morada:"",
        emergencia:"",
        estadia:"",
        responsavel:"",
        email_destino:"",
        telefone_destino:""
    })
        handleNewfield(val);
      };  

    return (
        <div>
            <section className="multi_step_form">
                <div className="msform">
                    <div className="tittle">
                        <h2>Pedido de Visto</h2>
                    </div>

                    <ul id="progressbar">
                        <li className="active">Dados Iniciais</li>
                        <li className="active">Visto</li>
                        <li>Pagamento</li>
                    </ul>
                    <motion.div
                    className="col-md-6 offset-md-3"
                    initial={{ x: '-100vw' }}
                    animate={{ x: 0 }}
                    transition={{     type: 'tween',
                    duration: 0.7 }}>
                        {
                        Object.keys(values).map((data,i) => {
                            return (                    
                            <div className="form-row">
                                <div className="row">
                                    <div className="col-sm">
                                        <label htmlFor="formGroupExampleInput">Nome</label>
                                        <input type="text" className="form-control"  name="nome" value={values[i].nome} onChange={e => handleChange("",i,e)} required ></input>
                                    </div>
                                    <div className="col-sm">
                                        <label htmlFor="formGroupExampleInput">Apelido</label>
                                        <input type="text" className="form-control"  name="apelido" value={values[i].apelido} onChange={e => handleChange("",i,e)} required ></input>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <label htmlFor="formGroupExampleInput">Email</label>
                                        <input className="form-control"  type="email" size="64" maxLength="64"
                                        placeholder="name@example.com" pattern="/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/" name="email" value={values[i].email} onChange={e => handleChange("",i,e)} required></input>
                                    </div>
                                    <div className="col-sm">
                                        <label htmlFor="formGroupExampleInput">Telefone</label>
                                        <input type="number" className="form-control"  name="telefone" value={values[i].telefone} onChange={e => handleChange("",i,e)} required></input>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <label htmlFor="formGroupExampleInput">Nacionalidade</label>
                                        <input className="form-control"  type="text" name="nacionalidade" minLength="4" value={values[i].nacionalidade} onChange={e => handleChange("",i,e)} required></input>
                                    </div>
                                    <div className="col-sm">
                                        <label htmlFor="formGroupExampleInput">Género</label>
                                        <select name ="genero" value={values[i].genero} onChange={e => handleChange("",i,e)} required className="form-control">
                                            <option>Selecione</option>
                                            <option value="homem">Homen</option>
                                            <option value="mulher">Mulher</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <label htmlFor="formGroupExampleInput">Data de Nascimento</label>
                                        <Form.Control type="date" className="form-control" id="form-date" name="data_nascimento" key={i} closeOnScroll={true} selected={values[i].data_nascimento} dateFormat="dd/MM/yyyy" onChange={(date)=>handleChange(date,"data_nascimento",i)} fixedHeight required/>
                                    </div>

                                    <div className="col-sm">
                                        <label htmlFor="formGroupExampleInput">Local de Nascimento</label>
                                        <input className="form-control" type="text" name="local_nascimento" value={values[i].local_nascimento} onChange={e => handleChange("",i,e)} required minLength="4"></input>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <label htmlFor="formGroupExampleInput">Data da Viagem</label>
                                        <Form.Control type="date" className="form-control" id="form-date" name="data_viagem" key={i} closeOnScroll={true}  dateFormat="dd/MM/yyyy" selected={values[i].data_viagem} onChange={(date)=>handleChange(date,"data_viagem",i)} fixedHeight required/>
                                    </div>

                                    <div className="col-sm">
                                        <label htmlFor="formGroupExampleInput">Data da Saída</label>
                                        <Form.Control type="date" className="form-control" id="form-date" name="data_saida" key={i} closeOnScroll={true} dateFormat="dd/MM/yyyy" selected={values[i].data_saida} onChange={(date)=>handleChange(date,"data_saida",i)} fixedHeight required/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <label htmlFor="formGroupExampleInput">Nº Passaporte</label>
                                        <input type="text" className="form-control"  name="num_pass" value={values[i].num_pass} onChange={e => handleChange("",i,e)} required ></input>
                                    </div>
                                    <div className="col-sm">
                                        <label htmlFor="formGroupExampleInput">Data de Emissão</label>
                                        <Form.Control type="date" className="form-control" id="form-date" name="data_emissao" key={i} closeOnScroll={true} dateFormat="dd/MM/yyyy" selected={values[i].data_emissao} onChange={(date)=>handleChange(date,"data_emissao",i)} required fixedHeight/>
                                    </div>
                                    <div className="col-sm">
                                        <label htmlFor="formGroupExampleInput">Data de Validade</label>
                                        <Form.Control type="date" className="form-control" id="form-date" name="data_validade" key={i} closeOnScroll={true} dateFormat="dd/MM/yyyy" selected={values[i].data_validade} onChange={(date)=>handleChange(date,"data_validade",i)} required fixedHeight/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <label>Países visitados nos últimos 5 anos</label>
                                        <input type="text" className="form-control" id="formGroupExampleInput" name="paises_visitados" value={values[i].paises_visitados} onChange={e => handleChange("",i,e)} required></input>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <label>Já obteve visto para o país? Se sim, quando e qual o número do visto?</label>
                                        <input type="text" className="form-control" id="formGroupExampleInput" name="visto_pais" value={values[i].visto_pais} onChange={e => handleChange("",i,e)} required></input>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <label htmlFor="formGroupExampleInput">Morada de residência</label>
                                        <input type="text" className="form-control"  name="morada" value={values[i].morada} onChange={e => handleChange("",i,e)} required ></input>
                                    </div>
                                    <div className="col-sm">
                                        <label htmlFor="formGroupExampleInput">Contato de emergência</label>
                                        <input type="number" className="form-control"  name="emergencia" value={values[i].emergencia} onChange={e => handleChange("",i,e)} required></input>
                                    </div>
                                </div>
                                <hr className="dashed"></hr>
                                <Button variant="outline-primary" onClick={handleAddFields}>Adicionar Pessoa</Button>
                            </div>
                            )})

                        }

                        <div className="row">
                            <div className="col-sm">
                            <label htmlFor="formGroupExampleInput">Local de estadia</label>
                            <input type="text" className="form-control"  name="estadia" value={values[0].estadia} onChange={e => handleChange("",0,e)} required></input>
                            </div>
                            <div className="col-sm">
                            <label htmlFor="formGroupExampleInput">Nome pessoa ou organização responsável</label>
                            <input type="text" className="form-control"  name="responsavel" value={values[0].responsavel} onChange={e => handleChange("",0,e)} required></input>
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-sm">
                            <label>Email país destino</label>
                            <input className="form-control" id="formGroupExampleInput" type="email" size="64" maxLength="64"
                            placeholder="name@example.com" pattern="/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/" name="email_destino" value={values[0].email_destino} onChange={e => handleChange("",0,e)} required></input>
                        </div>
                        <div className="col-sm">
                            <label>Telefone país destino</label>
                            <input type="number" className="form-control" id="formGroupExampleInput" name="telefone_destino" value={values[0].telefone_destino} onChange={e => handleChange("",0,e)} required></input>
                        </div>
                    </div>


                    </motion.div>

                    <button type="button" className="next action-button" onClick={Continue}>Continuar</button>
            
                </div>
            </section>
        </div>
    )
}
