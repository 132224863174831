import { BiSearch } from 'react-icons/bi'
import { FaAddressCard } from 'react-icons/fa'
// import { AiFillDelete } from 'react-icons/ai'
import ClientModal from './ClientModal'
// import DeleteModal from './DeleteModal'
import React, { useState } from "react"
import Header from "./Header";
import axios from 'axios';

function Search() {

    const [data, setData] = useState([])
    const [searchquery, setQuery] =  useState([])
    const [checkstatus, setStatus] = useState(false);
    const [client, setClient] = useState([])
    let [userdocs,setUserdocs]= useState([])

    const handleSubmit = (event) =>{
        event.preventDefault();

        const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({query:searchquery})
        };
        fetch('/api/searchpedido', requestOptions)
          .then(response => response.json())
          .then((response) => setData(response.result))
          .catch((err) => console.log('error'));
      }


    const passVals = (e) =>{

        axios.post("/api/download", {id:data[e.currentTarget.value].id_visto})
        .then((response) => {
          setUserdocs(response.data.message);
        })
        .catch((err) => console.log("error"));

        data.push()
        setStatus(true)
        setStatus({update:""})
        setClient(data[e.currentTarget.value])
        
    }

    const toggleModal = () => {
        setStatus(false);
    }

    // const toggleDelete = () => {
    //     setStatus_delete(false);
    // }

    return (
        <>
        <Header/>
        <div className="search-form">
            <form id="form-search">
            <div className="bar">
                <input type="text" name="search" placeholder="Introduza o nome do cliente" id="search-input" onChange={(e)=>setQuery(e.target.value)}/>
                <div className="search" name="search" id="search-icon" type="submit" onClick={handleSubmit} ><BiSearch color="white" fontSize="1.5em" /></div>
                <svg id="search-svg" width="400" height="50" viewBox="0 0 572 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M34 1H538C556.225 1 571 15.7746 571 34C571 52.2254 556.225 67 538 67H34C15.7746 67 1 52.2254 1 34C1 15.7746 15.7746 1 34 1Z" stroke="#11639E" strokeWidth="3"/>
                </svg>
            </div>
            </form>
        </div>


        <div className="search-result">
        <table className="search-table" id="search-values">
            <thead>
            <tr className="table-header">
                <td className="collumn1">ID</td>
                <td className="collumn2">Nome</td>
                <td className="collumn3">Email</td>
                <td className="collumn4">Telémovel</td>
                <td className="collumn5">Registo</td>
                <td className="collumn6">Tipo Visto</td>
                <td className="collumn7">Destino</td>
                <td className="collumn8">Pagamento</td>
                <td className="collumn9">Ações</td>
            </tr>
            </thead>
            <tbody>
            {data.map((item,i) => (
                <tr key={i}>
                    <td key={i+1} className="collumn1">{item.id}</td>
                    <td key={i+2} className="collumn2">{item.nome}</td>
                    <td key={i+3} className="collumn3">{item.email}</td>
                    <td key={i+4} className="collumn4">{item.telefone}</td>
                    <td key={i+5} className="collumn5">{item.data_registo}</td>
                    <td key={i+6} className="collumn6">{item.tipo_visto}</td>
                    <td key={i+7} className="collumn7">{item.pais}</td>
                    <td key={i+7} className="collumn8">{item.estado}</td>
                    <td key={i+8} className="collumn9" ><button value={i} onClick={passVals}><FaAddressCard className="icon-action" type="icon"/></button></td>
                </tr>
            ))}

            </tbody>
            </table>
            <ClientModal value={checkstatus} docs={userdocs} clientData={client} toggle={toggleModal}/>
            {/* <DeleteModal value={checkstatus_delete} clientData={client} toggle={toggleDelete}/> */}
        </div>
        </>
    )
}

export default Search;

