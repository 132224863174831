import { FaEnvelope } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../Hooks/userContext";
import { Alert } from "react-bootstrap";
import Header from "./Header";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";



export default function Login() {
  const [data, setData] = useState({
    username: "",
    password: "",
  });


  const { setUserContext } = useContext(AuthContext);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  function handleChange(evt) {
    const value = evt.target.value;
    setData({
      ...data,
      [evt.target.name]: value,
    });
  }

  async function submitLogin(event) {
    event.preventDefault();

    try {
      setError("");
      setLoading(true);
      var headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Accept", "application/json");

      const requestOptions = {
        method: "POST",
        credentials: "include", // Don't forget to specify this if you need cookies
        headers: headers,
        redirect: "follow",
        body: JSON.stringify({ name: data.username, password: data.password }),
      };

      const response = await fetch("/api/login", requestOptions);
      const result = await response.json();
      if (response.status >= 400 && response.status < 600) {
        throw new Error("Bad response from server");
      }
      console.log("loginResponse", result);
      
      setUserContext();
      result.user==="sofiavistos" ? navigate("/search") : navigate("/pedido")
      

    } catch (err) {
      setError("Failed to log in");
    }
    setLoading(false);
  }

  const register = () => {
    navigate("/registo");
  };

  const [selectedFile, setSelectedFile] = useState();

  const testFile = (e) =>{
    setSelectedFile(e.target.files);
  }

  const testeUpload = (e) =>{

    const formData = new FormData();
    console.log(selectedFile)
    formData.append("file",selectedFile[0]);
    console.log(formData)
    axios.post("/api/upload_docs", formData)
    .then((response) => {
      console.log(response);
    })
    .catch((err) => console.log("error"));

  }

  async function registerGuest (event) {
    const guest = "guest" + uuidv4();
    event.preventDefault();

    try {
      setError("");
      setLoading(true);
      var headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Accept", "application/json");

      const requestOptions = {
        method: "POST",
        credentials: "include", // Don't forget to specify this if you need cookies
        headers: headers,
        redirect: "follow",
        body: JSON.stringify({ guest:guest}),
      };

      const response = await fetch("/api/guest", requestOptions);
      const result = await response.json();
      if (response.status >= 400 && response.status < 600) {
        throw new Error("Bad response from server");
      }
      console.log("loginResponse", result);
      
      setUserContext();
      navigate("/pedido")
      

    } catch (err) {
      console.log(err)
      setError("Failed to log in");
    }
    setLoading(false);
  }

  return (
    <div>
      <Header></Header>
      <div className="container-login100">
        <div className="container-bg"></div>
        <div className="container-login">
          <div className="wrap-login100">
            <form
              className="login100-form validate-form"
              onSubmit={submitLogin}
            >
              <span className="login100-form-title">Bem Vindo!</span>

              <div
                className="wrap-input100 validate-input"
                data-validate="Valid email is required"
              >
                <input
                  className="input100 login-input"
                  type="text"
                  id="username"
                  name="username"
                  value={data.username}
                  placeholder="username"
                  onChange={handleChange}
                  required
                />
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                  <FaEnvelope />
                </span>
              </div>

              <div
                className="wrap-input100 validate-input"
                data-validate="Password is required"
              >
                <input
                  className="input100 login-input"
                  type="password"
                  id="psd"
                  name="password"
                  value={data.password}
                  placeholder="Password"
                  onChange={handleChange}
                  required
                />
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                  <RiLockPasswordFill />
                </span>
              </div>

              {error && <Alert variant="danger">{error}</Alert>}

              <div className="container-login100-form-btn login">
                <a href="/forgot">
                  <p>Esqueci-me da password?</p>
                </a>
                <button className="login100-form-btn" type="submit">
                  Login
                </button>
              </div>
              <div className="container-login100-form-btn btn-flex">
                <button className="login100-form-btn" onClick={register}>
                  Registar
                </button>
                <button className="guest-btn" onClick={registerGuest}>
                  <p>Continuar como Guest?</p>
                </button>
              </div>
            </form>
            <input type="file" name="file" multiple onChange={testFile}/> 
                <button onClick={testeUpload}>Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
}
