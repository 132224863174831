import React, {useEffect, useState } from "react";
import AuthContext from "../Hooks/userContext";
import axios from "axios";

export default function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const setUserContext = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/user", { withCredentials: true });
      login(res.data.currentUser);
    } catch (err) {
      console.log(err);
      setError(err);
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setUserContext();
  }, []);

  function login(loggedUser) {
    setUser(loggedUser);
  }

  function logout() {
    setUser(null);
  }

  const value = {
    user,
    login,
    logout,
    error,
    loading,
    setUserContext,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
