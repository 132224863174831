import React from 'react'
import Header from './Header'

export default function Sucess() {
    return (
        
        <div>
            <Header></Header>
            <div className="card">
                <div className="icon">
                    <i className='checkmark'>X</i>
                </div>
                <h1>Failed</h1> 
                <p>Infelizmente o seu pagamento não conseguiu ser processado, em breve iremos entrar em contacto consigo</p>
                <button className="login100-form-btn"><a href="https://sofiamachado.pt/">Página Inicial</a></button>
        </div>
        </div>
    )
}
