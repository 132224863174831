import React, { useState } from 'react'
import Header from './Header'
import { FaEnvelope } from 'react-icons/fa'
import { Alert } from 'react-bootstrap'
import axios from 'axios' 

function Forgot() {

    const [error, setError] = useState("");
    const [email, setEmail] = useState("");
    const [sucess, setSucess] = useState("");

    async function submitEmail(e){
        e.preventDefault()
        setEmail(e.target.value)
    }


    async function submitForgot(e){
        e.preventDefault()
        setError("")
        setSucess("")
        
        try {
            await axios.post("/api/useremailcheck",{email:email}).then(res =>{
                if(res.data.message.length === 0){
                    setError("Este email não possui conta associada")
                    return false;
                }

                else{
                    axios.post("/api/forgotlogin",{email:email}).then(res =>{
                        
                        if(res.data.message.length !== 0){
                            setSucess("Email enviado com sucesso")
                        }
                    })
                }
            })
          } catch (err) {
            setError(err);
          }

    }

  return (
        <div>
      <Header></Header>
      <div className="container-login100">
        <div className="container-bg"></div>
        <div className="container-login">
          <div className="wrap-login100">
            <form
              className="login100-form validate-form"
              onSubmit={submitForgot}
            >
              <span className="login100-form-title">Reset Password</span>

              <div className="wrap-input100 validate-input">
                <input
                  className="input100 login-input"
                  type="email"
                  name="email"
                  value={email}
                  onChange={submitEmail}
                  placeholder="Insira o seu email"
                  required
                />
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                  <FaEnvelope />
                </span>
              </div>

              {error && <Alert variant="danger">{error}</Alert>}
              {sucess && <Alert variant="success">{sucess}</Alert>}

              <div className="container-login100-form-btn login">
                <button className="login100-form-btn" type="submit">
                  Enviar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Forgot