const pricedata={
    "Preços": [
        {
            "País": "Angola",
            "Tipo de Visto": "Visto Turismo",
            "Prazo de emissão": "3 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "60 dias",
            "Taxas Consulares": " 70.80 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": "160.80 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto Turismo",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "60 dias",
            "Taxas Consulares": " 85.00 € ",
            "Taxa de Serviços": " 100.00 € ",
            "Total": "185.00 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto Curta-Duração",
            "Prazo de emissão": "3 dias",
            "Número de entradas": "1 entrada ",
            "Duração da estadia": "7 dias",
            "Validade do visto": "7 dias",
            "Taxas Consulares": " 80.80 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": "170.80 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto Curta-Duração",
            "Prazo de emissão": "24 H",
            "Número de entradas": "1 entrada ",
            "Duração da estadia": "7 dias",
            "Validade do visto": "7 dias",
            "Taxas Consulares": " 100.00 € ",
            "Taxa de Serviços": " 100.00 € ",
            "Total": "200.00 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto Curta-Duração",
            "Prazo de emissão": "Próprio dia",
            "Número de entradas": "1 entrada ",
            "Duração da estadia": "7 dias",
            "Validade do visto": "7 dias",
            "Taxas Consulares": " 270.80 € ",
            "Taxa de Serviços": " 110.00 € ",
            "Total": "380.80 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto de Trânsito",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "72h",
            "Validade do visto": "7 dias",
            "Taxas Consulares": " 45.80 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": "135.80 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto de Estudo",
            "Prazo de emissão": "30 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "Variável",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 145.80 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": "235.80 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto de Estudo",
            "Prazo de emissão": "15 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "Variável",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 180.00 € ",
            "Taxa de Serviços": " 100.00 € ",
            "Total": "280.00 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto de Tratamento Médico",
            "Prazo de emissão": "30 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "Variável",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 50.80 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": "140.80 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto de Tratamento Médico",
            "Prazo de emissão": "15 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "Variável",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 60.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": "150.00 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto Ordinário Abrigo Protocolo 3 meses",
            "Prazo de emissão": "8 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 200.80 € ",
            "Taxa de Serviços": " 100.00 € ",
            "Total": "300.80 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto Ordinário Abrigo Protocolo 3 meses",
            "Prazo de emissão": "4 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 250.00 € ",
            "Taxa de Serviços": " 110.00 € ",
            "Total": "360.00 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto Ordinário Abrigo Protocolo 6 meses",
            "Prazo de emissão": "8 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "180 dias",
            "Taxas Consulares": " 285.80 € ",
            "Taxa de Serviços": " 100.00 € ",
            "Total": "385.80 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto Ordinário Abrigo Protocolo 6 meses",
            "Prazo de emissão": "4 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "180 dias",
            "Taxas Consulares": " 355.00 € ",
            "Taxa de Serviços": " 130.00 € ",
            "Total": "485.00 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto Ordinário Abrigo Protocolo 12 meses",
            "Prazo de emissão": "8 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias por semestre",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 470.80 € ",
            "Taxa de Serviços": " 100.00 € ",
            "Total": "570.80 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto Ordinário Abrigo Protocolo 12 meses",
            "Prazo de emissão": "4 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias por semestre",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 590.00 € ",
            "Taxa de Serviços": " 130.00 € ",
            "Total": "720.00 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto Ordinário Abrigo Protocolo 18 meses",
            "Prazo de emissão": "8 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias por semestre",
            "Validade do visto": "545 dias",
            "Taxas Consulares": " 660.80 € ",
            "Taxa de Serviços": " 100.00 € ",
            "Total": "760.80 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto Ordinário Abrigo Protocolo 18 meses",
            "Prazo de emissão": "4 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias por semestre",
            "Validade do visto": "545 dias",
            "Taxas Consulares": " 825.00 € ",
            "Taxa de Serviços": " 130.00 € ",
            "Total": "955.00 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto Ordinário Abrigo Protocolo 24 meses",
            "Prazo de emissão": "8 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias por semestre",
            "Validade do visto": "720 dias",
            "Taxas Consulares": " 848.80 € ",
            "Taxa de Serviços": " 100.00 € ",
            "Total": "948.80 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto Ordinário Abrigo Protocolo 24 meses",
            "Prazo de emissão": "4 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias por semestre",
            "Validade do visto": "720 dias",
            "Taxas Consulares": " 1,060.00 € ",
            "Taxa de Serviços": " 130.00 € ",
            "Total": "1,190.00 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto Ordinário Abrigo Protocolo 36 meses",
            "Prazo de emissão": "8 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias por semestre",
            "Validade do visto": "1095 dias",
            "Taxas Consulares": " 1,130.80 € ",
            "Taxa de Serviços": " 100.00 € ",
            "Total": "1,230.80 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto Ordinário Abrigo Protocolo 36 meses",
            "Prazo de emissão": "4 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias por semestre",
            "Validade do visto": "1095 dias",
            "Taxas Consulares": " 1,410.00 € ",
            "Taxa de Serviços": " 130.00 € ",
            "Total": "1,540.00 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto Trabalho",
            "Prazo de emissão": "30 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "365 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 235.80 € ",
            "Taxa de Serviços": " 150.00 € ",
            "Total": "385.80 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto Trabalho",
            "Prazo de emissão": "15 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "365 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 295.00 € ",
            "Taxa de Serviços": " 175.00 € ",
            "Total": "470.00 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto Trabalho Abrigo Protocolo 24 meses",
            "Prazo de emissão": "30 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "720 dias",
            "Validade do visto": "720 dias",
            "Taxas Consulares": " 470.80 € ",
            "Taxa de Serviços": " 170.00 € ",
            "Total": "640.80 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto Trabalho Abrigo Protocolo 24 meses",
            "Prazo de emissão": "30 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "720 dias",
            "Validade do visto": "720 dias",
            "Taxas Consulares": " 590.00 € ",
            "Taxa de Serviços": " 200.00 € ",
            "Total": "790.00 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto Trabalho Abrigo Protocolo 36 meses",
            "Prazo de emissão": "30 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "1095 dias",
            "Validade do visto": "1095 dias",
            "Taxas Consulares": " 708.80 € ",
            "Taxa de Serviços": " 170.00 € ",
            "Total": "878.80 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto Trabalho Abrigo Protocolo 36 meses",
            "Prazo de emissão": "30 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "1095 dias",
            "Validade do visto": "1095 dias",
            "Taxas Consulares": " 885.00 € ",
            "Taxa de Serviços": " 200.00 € ",
            "Total": "1,085.00 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto de Permanência Temporária",
            "Prazo de emissão": "30 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "365 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 145.80 € ",
            "Taxa de Serviços": " 150.00 € ",
            "Total": "295.80 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto de Permanência Temporária",
            "Prazo de emissão": "15 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "365 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 180.00 € ",
            "Taxa de Serviços": " 175.00 € ",
            "Total": "355.00 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto de Fixação e Residência",
            "Prazo de emissão": "30 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "120 dias",
            "Validade do visto": "120 dias",
            "Taxas Consulares": " 190.80 € ",
            "Taxa de Serviços": " 150.00 € ",
            "Total": "340.80 €"
        },
        {
            "País": "Angola",
            "Tipo de Visto": "Visto de Fixação e Residência",
            "Prazo de emissão": "30 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "120 dias",
            "Validade do visto": "120 dias",
            "Taxas Consulares": " 238.00 € ",
            "Taxa de Serviços": " 175.00 € ",
            "Total": "413.00 €"
        },
        {
            "País": "Arábia Saudita",
            "Tipo de Visto": "Visto de Turismo",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "30 dias",
            "Taxas Consulares": " 132.00 € ",
            "Taxa de Serviços": " 80.00 € ",
            "Total": " 212.00 € "
        },
        {
            "País": "Arábia Saudita",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 132.00 € ",
            "Taxa de Serviços": " 80.00 € ",
            "Total": " 212.00 € "
        },
        {
            "País": "Arábia Saudita",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 132.00 € ",
            "Taxa de Serviços": " 80.00 € ",
            "Total": " 212.00 € "
        },
        {
            "País": "Arábia Saudita",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 132.00 € ",
            "Taxa de Serviços": " 100.00 € ",
            "Total": " 232.00 € "
        },
        {
            "País": "Arábia Saudita",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 132.00 € ",
            "Taxa de Serviços": " 100.00 € ",
            "Total": " 232.00 € "
        },
        {
            "País": "Arábia Saudita",
            "Tipo de Visto": "Visto de Trabalho",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Single entry",
            "Duração da estadia": "90 dias",
            "Validade do visto": "120 dias",
            "Taxas Consulares": " 132.00 € ",
            "Taxa de Serviços": " 80.00 € ",
            "Total": " 212.00 € "
        },
        {
            "País": "Arábia Saudita",
            "Tipo de Visto": "Visto de Trabalho",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "Single entry",
            "Duração da estadia": "90 dias",
            "Validade do visto": "120 dias",
            "Taxas Consulares": " 132.00 € ",
            "Taxa de Serviços": " 100.00 € ",
            "Total": " 232.00 € "
        },
        {
            "País": "Arábia Saudita",
            "Tipo de Visto": "Visto Eletrónico",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 132.00 € ",
            "Taxa de Serviços": " 100.00 € ",
            "Total": " 232.00 € "
        },
        {
            "País": "Arábia Saudita",
            "Tipo de Visto": "Visto Eletrónico",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 132.00 € ",
            "Taxa de Serviços": " 100.00 € ",
            "Total": " 232.00 € "
        },
        {
            "País": "Argélia",
            "Tipo de Visto": "Visto de Turismo (+ 12 nos)",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 80.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 170.00 € "
        },
        {
            "País": "Argélia",
            "Tipo de Visto": "Visto de Turismo (2-12 anos)",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 40.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 130.00 € "
        },
        {
            "País": "Argélia",
            "Tipo de Visto": "Visto de Turismo (-6 anos)",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " -   € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 90.00 € "
        },
        {
            "País": "Argélia",
            "Tipo de Visto": "Visto de Turismo",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 100.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 190.00 € "
        },
        {
            "País": "Argélia",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 80.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 170.00 € "
        },
        {
            "País": "Argélia",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 100.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 190.00 € "
        },
        {
            "País": "Argélia",
            "Tipo de Visto": "Visto de Familiar (+ 12 nos)",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 80.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 170.00 € "
        },
        {
            "País": "Argélia",
            "Tipo de Visto": "Visto de Familiar (2-12 anos)",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 40.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 130.00 € "
        },
        {
            "País": "Argélia",
            "Tipo de Visto": "Visto de Familiar (-6 anos)",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " -   € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 90.00 € "
        },
        {
            "País": "Argélia",
            "Tipo de Visto": "Visto de Familiar",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 100.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 190.00 € "
        },
        {
            "País": "Argélia",
            "Tipo de Visto": "Visto de Trabalho Temporário",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 80.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 170.00 € "
        },
        {
            "País": "Argélia",
            "Tipo de Visto": "Visto de Trabalho",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 100.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 190.00 € "
        },
        {
            "País": "Brasil",
            "Tipo de Visto": "Visto de Negócios (VIVIS)",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 80.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 170.00 € "
        },
        {
            "País": "Brasil",
            "Tipo de Visto": "Visto de Negócios (VIVIS) Angola",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 180.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 270.00 € "
        },
        {
            "País": "Brasil",
            "Tipo de Visto": "Visto de Negócios (VIVIS) Austrália",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 120.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 210.00 € "
        },
        {
            "País": "Brasil",
            "Tipo de Visto": "Visto de Negócios (VIVIS) EUA",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 160.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 250.00 € "
        },
        {
            "País": "Brasil",
            "Tipo de Visto": "Visto de Negócios (VIVIS)",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 80.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 170.00 € "
        },
        {
            "País": "Brasil",
            "Tipo de Visto": "Visto de Negócios (VIVIS) Angola",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 180.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 270.00 € "
        },
        {
            "País": "Brasil",
            "Tipo de Visto": "Visto de Negócios (VIVIS) Austrália",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 120.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 210.00 € "
        },
        {
            "País": "Brasil",
            "Tipo de Visto": "Visto de Negócios (VIVIS) EUA",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 160.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 250.00 € "
        },
        {
            "País": "Brasil",
            "Tipo de Visto": "Visto de Turismo (VIVIS)",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 80.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 170.00 € "
        },
        {
            "País": "Brasil",
            "Tipo de Visto": "Visto de Turismo (VIVIS) Angola",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 180.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 270.00 € "
        },
        {
            "País": "Brasil",
            "Tipo de Visto": "Visto de Turismo (VIVIS) Austrália",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 120.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 210.00 € "
        },
        {
            "País": "Brasil",
            "Tipo de Visto": "Visto de Turismo (VIVIS) EUA",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 160.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 250.00 € "
        },
        {
            "País": "Brasil",
            "Tipo de Visto": "Visto de Turismo (VIVIS)",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 80.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 170.00 € "
        },
        {
            "País": "Brasil",
            "Tipo de Visto": "Visto de Turismo (VIVIS) Angola",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 180.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 270.00 € "
        },
        {
            "País": "Brasil",
            "Tipo de Visto": "Visto de Turismo (VIVIS) Austrália",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 120.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 210.00 € "
        },
        {
            "País": "Brasil",
            "Tipo de Visto": "Visto de Turismo (VIVIS) EUA",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 160.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 250.00 € "
        },
        {
            "País": "Brasil",
            "Tipo de Visto": "Visto de Estudo IV",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "365 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 100.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 190.00 € "
        },
        {
            "País": "Brasil",
            "Tipo de Visto": "Visto de Estudo IV (UK)",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "365 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 465.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 555.00 € "
        },
        {
            "País": "Brasil",
            "Tipo de Visto": "Visto de Estudo IV (EUA)",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "365 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 160.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 250.00 € "
        },
        {
            "País": "Brasil",
            "Tipo de Visto": "Visto de Trabalho V",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "365 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 100.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 190.00 € "
        },
        {
            "País": "Brasil",
            "Tipo de Visto": "Visto de Trabalho V (EUA)",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "365 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 290.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 380.00 € "
        },
        {
            "País": "Cazaquistão",
            "Tipo de Visto": "Visto de Turismo ",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Single entry",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 75.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 165.00 € "
        },
        {
            "País": "Cazaquistão",
            "Tipo de Visto": "Visto de Visita Particular",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Single entry",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 75.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 165.00 € "
        },
        {
            "País": "Cazaquistão",
            "Tipo de Visto": "Visto de Visita Particular",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "180 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 170.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 260.00 € "
        },
        {
            "País": "Cazaquistão",
            "Tipo de Visto": "Visto de Visita Particular",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "180 dias",
            "Validade do visto": "2 anos",
            "Taxas Consulares": " 330.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 420.00 € "
        },
        {
            "País": "Cazaquistão",
            "Tipo de Visto": "Visto de Visita Particular",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "180 dias",
            "Validade do visto": "3 anos",
            "Taxas Consulares": " 450.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 540.00 € "
        },
        {
            "País": "Cazaquistão",
            "Tipo de Visto": "Visto de Visita Particular",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "180 dias",
            "Validade do visto": "4 anos",
            "Taxas Consulares": " 600.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 690.00 € "
        },
        {
            "País": "Cazaquistão",
            "Tipo de Visto": "Visto de Visita Particular",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "180 dias",
            "Validade do visto": "5 anos",
            "Taxas Consulares": " 850.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 940.00 € "
        },
        {
            "País": "Cazaquistão",
            "Tipo de Visto": "Visto de Turismo ",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 80.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 170.00 € "
        },
        {
            "País": "Cazaquistão",
            "Tipo de Visto": "Visto de Turismo ",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 75.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 165.00 € "
        },
        {
            "País": "Cazaquistão",
            "Tipo de Visto": "Visto de Turismo ",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 75.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 165.00 € "
        },
        {
            "País": "Cazaquistão",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Single entry",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 75.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 165.00 € "
        },
        {
            "País": "Cazaquistão",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 170.00 € ",
            "Total": " 170.00 € "
        },
        {
            "País": "Cazaquistão",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "180 dias",
            "Validade do visto": "2 anos",
            "Taxas Consulares": " 330.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 420.00 € "
        },
        {
            "País": "Cazaquistão",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "180 dias",
            "Validade do visto": "3 anos",
            "Taxas Consulares": " 450.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 540.00 € "
        },
        {
            "País": "Cazaquistão",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "180 dias",
            "Validade do visto": "4 anos",
            "Taxas Consulares": " 600.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 690.00 € "
        },
        {
            "País": "Cazaquistão",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas",
            "Duração da estadia": "180 dias",
            "Validade do visto": "5 anos",
            "Taxas Consulares": " 850.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 940.00 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Turismo (Países Schengen)",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "1, 2 ou multiplas entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Até 24 meses",
            "Taxas Consulares": " 127.65 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 217.65 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Turismo (Países Schengen)",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "1, 2 ou multiplas entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Até 24 meses",
            "Taxas Consulares": " 173.25 € ",
            "Taxa de Serviços": " 110.00 € ",
            "Total": " 283.25 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Turismo (Americanos)",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "1, 2 ou multiplas entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Até 24 meses",
            "Taxas Consulares": " 181.65 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 271.65 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Turismo (Americanos)",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "1, 2 ou multiplas entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Até 24 meses",
            "Taxas Consulares": " 227.25 € ",
            "Taxa de Serviços": " 110.00 € ",
            "Total": " 337.25 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Turismo (Canadianos)",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "1, 2 ou multiplas entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Até 24 meses",
            "Taxas Consulares": " 141.65 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 231.65 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Turismo (Canadianos)",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "1, 2 ou multiplas entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Até 24 meses",
            "Taxas Consulares": " 187.25 € ",
            "Taxa de Serviços": " 110.00 € ",
            "Total": " 297.25 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Turismo (Outros Países)",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "180 dias",
            "Taxas Consulares": " 133.65 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 223.65 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Turismo (Outros Países)",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "180 dias",
            "Taxas Consulares": " 179.25 € ",
            "Taxa de Serviços": " 110.00 € ",
            "Total": " 289.25 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Turismo (Outros Países)",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 167.65 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 257.65 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Turismo (Outros Países)",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 213.25 € ",
            "Taxa de Serviços": " 110.00 € ",
            "Total": " 323.25 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Turismo (Outros Países)",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "180 dias",
            "Taxas Consulares": " 100.65 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 190.65 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Turismo (Outros Países)",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "180 dias",
            "Taxas Consulares": " 146.25 € ",
            "Taxa de Serviços": " 110.00 € ",
            "Total": " 256.25 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Turismo (Outros Países)",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "2 entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 117.65 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 207.65 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Turismo (Outros Países)",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "2 entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 doas",
            "Taxas Consulares": " 163.25 € ",
            "Taxa de Serviços": " 110.00 € ",
            "Total": " 273.25 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Negócios (Países Schengen)",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "1, 2 ou multiplas entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Até 24 meses",
            "Taxas Consulares": " 127.65 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 217.65 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Negócios (Países Schengen)",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "1, 2 ou multiplas entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Até 24 meses",
            "Taxas Consulares": " 173.25 € ",
            "Taxa de Serviços": " 110.00 € ",
            "Total": " 283.25 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Negócios (Americanos)",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "1, 2 ou multiplas entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Até 24 meses",
            "Taxas Consulares": " 181.65 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 271.65 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Negócios (Americanos)",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "1, 2 ou multiplas entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Até 24 meses",
            "Taxas Consulares": " 227.25 € ",
            "Taxa de Serviços": " 110.00 € ",
            "Total": " 337.25 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Negócios (Canadianos)",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "1, 2 ou multiplas entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Até 24 meses",
            "Taxas Consulares": " 141.65 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 231.65 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Negócios (Canadianos)",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "1, 2 ou multiplas entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Até 24 meses",
            "Taxas Consulares": " 187.25 € ",
            "Taxa de Serviços": " 110.00 € ",
            "Total": " 297.25 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Negócios (Outros Países)",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "180 dias",
            "Taxas Consulares": " 133.65 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 223.65 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Negócios (Outros Países)",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "180 dias",
            "Taxas Consulares": " 179.25 € ",
            "Taxa de Serviços": " 110.00 € ",
            "Total": " 289.25 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Negócios (Outros Países)",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 167.65 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 257.65 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Negócios (Outros Países)",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "365 dias",
            "Taxas Consulares": " 213.25 € ",
            "Taxa de Serviços": " 110.00 € ",
            "Total": " 323.25 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Negócios (Outros Países)",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "180 dias",
            "Taxas Consulares": " 100.65 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 190.65 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Negócios (Outros Países)",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "180 dias",
            "Taxas Consulares": " 146.25 € ",
            "Taxa de Serviços": " 110.00 € ",
            "Total": " 256.25 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Negócios (Outros Países)",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "2 entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 117.65 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 207.65 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Negócios (Outros Países)",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "2 entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 163.25 € ",
            "Taxa de Serviços": " 110.00 € ",
            "Total": " 273.25 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Trabalho",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "30 dias",
            "Taxas Consulares": " 127.65 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 217.65 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Trabalho",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "30 dias",
            "Taxas Consulares": " 173.25 € ",
            "Taxa de Serviços": " 110.00 € ",
            "Total": " 283.25 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Estudante",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "30 dias",
            "Taxas Consulares": " 127.65 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 217.65 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Estudante",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "30 dias",
            "Taxas Consulares": " 173.25 € ",
            "Taxa de Serviços": " 110.00 € ",
            "Total": " 283.25 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Crew Member",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Variável",
            "Duração da estadia": "Variável",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 127.65 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 217.65 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Crew Member",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "Variável",
            "Duração da estadia": "Variável",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 173.25 € ",
            "Taxa de Serviços": " 110.00 € ",
            "Total": " 283.25 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Trânsito",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Variável",
            "Duração da estadia": "Variável",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 127.65 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 217.65 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Trânsito",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "Variável",
            "Duração da estadia": "Variável",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 173.25 € ",
            "Taxa de Serviços": " 110.00 € ",
            "Total": " 283.25 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Prestação de Serviços",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Variável",
            "Duração da estadia": "Variável",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 127.65 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 217.65 € "
        },
        {
            "País": "China",
            "Tipo de Visto": "Visto de Prestação de Serviços",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "Variável",
            "Duração da estadia": "Variável",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 173.25 € ",
            "Taxa de Serviços": " 110.00 € ",
            "Total": " 283.25 € "
        },
        {
            "País": "Congo Brazzaville",
            "Tipo de Visto": "Turismo (Paris)",
            "Prazo de emissão": "12 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "15 dias",
            "Validade do visto": "15 dias",
            "Taxas Consulares": " 55.00 € ",
            "Taxa de Serviços": " 120.00 € ",
            "Total": " 175.00 € "
        },
        {
            "País": "Congo Brazzaville",
            "Tipo de Visto": "Turismo (Paris)",
            "Prazo de emissão": "4 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "15 dias",
            "Validade do visto": "15 dias",
            "Taxas Consulares": " 110.00 € ",
            "Taxa de Serviços": " 150.00 € ",
            "Total": " 260.00 € "
        },
        {
            "País": "Congo Brazzaville",
            "Tipo de Visto": "Turismo (Paris)",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 110.00 € ",
            "Taxa de Serviços": " 120.00 € ",
            "Total": " 230.00 € "
        },
        {
            "País": "Congo Brazzaville",
            "Tipo de Visto": "Turismo (Paris)",
            "Prazo de emissão": "4 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 220.00 € ",
            "Taxa de Serviços": " 150.00 € ",
            "Total": " 370.00 € "
        },
        {
            "País": "Congo Brazzaville",
            "Tipo de Visto": "Negócios (Paris)",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "15 dias",
            "Validade do visto": "15 dias",
            "Taxas Consulares": " 55.00 € ",
            "Taxa de Serviços": " 120.00 € ",
            "Total": " 175.00 € "
        },
        {
            "País": "Congo Brazzaville",
            "Tipo de Visto": "Negócios (Paris)",
            "Prazo de emissão": "4 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "15 dias",
            "Validade do visto": "15 dias",
            "Taxas Consulares": " 110.00 € ",
            "Taxa de Serviços": " 150.00 € ",
            "Total": " 260.00 € "
        },
        {
            "País": "Congo Brazzaville",
            "Tipo de Visto": "Negócios (Paris)",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 110.00 € ",
            "Taxa de Serviços": " 120.00 € ",
            "Total": " 230.00 € "
        },
        {
            "País": "Congo Brazzaville",
            "Tipo de Visto": "Negócios (Paris)",
            "Prazo de emissão": "4 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 220.00 € ",
            "Taxa de Serviços": " 150.00 € ",
            "Total": " 370.00 € "
        },
        {
            "País": "Gabão",
            "Tipo de Visto": "Visto Eletrónico Turismo",
            "Prazo de emissão": "3 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "30 a 90 dias",
            "Taxas Consulares": " 85.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 175.00 € "
        },
        {
            "País": "Gabão",
            "Tipo de Visto": "Visto Eletrónico Turismo",
            "Prazo de emissão": "4 dias",
            "Número de entradas": "Entradas Múltiplas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "180 dias",
            "Taxas Consulares": " 200.00 € ",
            "Taxa de Serviços": " 100.00 € ",
            "Total": " 300.00 € "
        },
        {
            "País": "Gabão",
            "Tipo de Visto": "Visto de Turismo (Paris)",
            "Prazo de emissão": "6 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "180 dias",
            "Taxas Consulares": " 55.00 € ",
            "Taxa de Serviços": " 120.00 € ",
            "Total": " 175.00 € "
        },
        {
            "País": "Gabão",
            "Tipo de Visto": "Visto de Turismo (Paris)",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias ",
            "Validade do visto": "180 dias",
            "Taxas Consulares": " 110.00 € ",
            "Taxa de Serviços": " 160.00 € ",
            "Total": " 270.00 € "
        },
        {
            "País": "Gabão",
            "Tipo de Visto": "Visto de Negócios (Paris)",
            "Prazo de emissão": "6 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "180 dias",
            "Taxas Consulares": " 55.00 € ",
            "Taxa de Serviços": " 120.00 € ",
            "Total": " 175.00 € "
        },
        {
            "País": "Gabão",
            "Tipo de Visto": "Visto de Negócios (Paris)",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias ",
            "Validade do visto": "180 dias",
            "Taxas Consulares": " 110.00 € ",
            "Taxa de Serviços": " 160.00 € ",
            "Total": " 270.00 € "
        },
        {
            "País": "Guiné Equatorial",
            "Tipo de Visto": "Visto de Turismo",
            "Prazo de emissão": "8 dias",
            "Número de entradas": "1 entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "30 dias",
            "Taxas Consulares": " 100.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 190.00 € "
        },
        {
            "País": "Guiné Equatorial",
            "Tipo de Visto": "Visto de Turismo",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "30 dias",
            "Taxas Consulares": " 200.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 290.00 € "
        },
        {
            "País": "Guiné Equatorial",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "8 dias",
            "Número de entradas": "1 entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "30 dias",
            "Taxas Consulares": " 100.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 190.00 € "
        },
        {
            "País": "Guiné Equatorial",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "2 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "30 dias",
            "Taxas Consulares": " 200.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 290.00 € "
        },
        {
            "País": "Guiné Equatorial",
            "Tipo de Visto": "Visto Diplomático",
            "Prazo de emissão": "1 dia",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "30 dias",
            "Taxas Consulares": " 100.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 190.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto Turismo",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "30 dias",
            "Taxas Consulares": " 60.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": "150 €"
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto Turismo",
            "Prazo de emissão": "3 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "30 dias",
            "Taxas Consulares": " 80.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": "170 €"
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto Turismo",
            "Prazo de emissão": "Próprio dia",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "30 dias",
            "Taxas Consulares": " 150.00 € ",
            "Taxa de Serviços": " 39.00 € ",
            "Total": "189 €"
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto Turismo",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 165.00 € ",
            "Taxa de Serviços": " 100.00 € ",
            "Total": "265 €"
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto Turismo",
            "Prazo de emissão": "3 dias",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": "210 €",
            "Taxa de Serviços": "110 €",
            "Total": "320 €"
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto Turismo",
            "Prazo de emissão": "Próprio dia",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": "240 €",
            "Taxa de Serviços": "120 €",
            "Total": "360 €"
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto Negócios",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "30 dias",
            "Taxas Consulares": "60 €",
            "Taxa de Serviços": "90 €",
            "Total": "150 €"
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto Negócios",
            "Prazo de emissão": "3 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "30 dias",
            "Taxas Consulares": "80 €",
            "Taxa de Serviços": "90 €",
            "Total": "170 €"
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto Negócios",
            "Prazo de emissão": "Próprio dia",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "30 dias",
            "Taxas Consulares": "150 €",
            "Taxa de Serviços": "39 €",
            "Total": "189 €"
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto Negócios",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": "165 €",
            "Taxa de Serviços": "100 €",
            "Total": "265 €"
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto Negócios",
            "Prazo de emissão": "3 dias",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": "210 €",
            "Taxa de Serviços": "110 €",
            "Total": "320 €"
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto Negócios",
            "Prazo de emissão": "Próprio dia",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": "240 €",
            "Taxa de Serviços": "120 €",
            "Total": "360 €"
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto Trabalho",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "60 dias",
            "Taxas Consulares": "350 €",
            "Taxa de Serviços": "130 €",
            "Total": "480 €"
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto Permanência Temporária",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "60 dias",
            "Taxas Consulares": "350 €",
            "Taxa de Serviços": "130 €",
            "Total": "480 €"
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto Trânsito",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "7 dias",
            "Validade do visto": "7 dias",
            "Taxas Consulares": "30 €",
            "Taxa de Serviços": "90 €",
            "Total": "120 €"
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto Trânsito",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "7 dias",
            "Validade do visto": "7 dias",
            "Taxas Consulares": "35 €",
            "Taxa de Serviços": "90 €",
            "Total": "125 €"
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto Trânsito",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "7 dias",
            "Validade do visto": "7 dias",
            "Taxas Consulares": "40 €",
            "Taxa de Serviços": "90 €",
            "Total": "130 €"
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto Residência",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "60 dias",
            "Taxas Consulares": "120 €",
            "Taxa de Serviços": "90 €",
            "Total": "210 €"
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto Estudante",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "60 dias",
            "Taxas Consulares": "120 €",
            "Taxa de Serviços": "90 €",
            "Total": "210 €"
        },
        {
            "País": "RDC",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "30 dias",
            "Taxas Consulares": " 200.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 290.00 € "
        },
        {
            "País": "RDC",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "60 dias",
            "Taxas Consulares": " 260.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 350.00 € "
        },
        {
            "País": "RDC",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 320.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 410.00 € "
        },
        {
            "País": "RDC",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "180 dias",
            "Taxas Consulares": " 400.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 490.00 € "
        },
        {
            "País": "RDC",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "30 dias",
            "Taxas Consulares": " 230.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 320.00 € "
        },
        {
            "País": "RDC",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "60 dias",
            "Taxas Consulares": " 300.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 390.00 € "
        },
        {
            "País": "RDC",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 350.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 440.00 € "
        },
        {
            "País": "RDC",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "180 dias",
            "Taxas Consulares": " 450.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 540.00 € "
        },
        {
            "País": "Senegal",
            "Tipo de Visto": "Turismo / Negócios",
            "Prazo de emissão": "3 dias",
            "Número de entradas": "1 entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 15.25 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 105.25 € "
        },
        {
            "País": "EUA",
            "Tipo de Visto": "H1/B1 Turismo, Negócios, Tratamento Médico",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Variável",
            "Duração da estadia": "90 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 155.00 € ",
            "Taxa de Serviços": " 100.00 € ",
            "Total": " 255.00 € "
        },
        {
            "País": "EUA",
            "Tipo de Visto": "C1/D Crew Member",
            "Prazo de emissão": "6 dias",
            "Número de entradas": "Variável",
            "Duração da estadia": "90 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 155.00 € ",
            "Taxa de Serviços": " 100.00 € ",
            "Total": " 255.00 € "
        },
        {
            "País": "EUA",
            "Tipo de Visto": "L - Transferência entre Empresa",
            "Prazo de emissão": "7 dias",
            "Número de entradas": "Variável",
            "Duração da estadia": "90 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 185.00 € ",
            "Taxa de Serviços": " 100.00 € ",
            "Total": " 285.00 € "
        },
        {
            "País": "EUA",
            "Tipo de Visto": "H-1B Profissões Especializadas",
            "Prazo de emissão": "8 dias",
            "Número de entradas": "Variável",
            "Duração da estadia": "90 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 185.00 € ",
            "Taxa de Serviços": " 100.00 € ",
            "Total": " 285.00 € "
        },
        {
            "País": "EUA",
            "Tipo de Visto": "H-1B1 Acordo Comércio Livre",
            "Prazo de emissão": "9 dias",
            "Número de entradas": "Variável",
            "Duração da estadia": "90 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 185.00 € ",
            "Taxa de Serviços": " 100.00 € ",
            "Total": " 285.00 € "
        },
        {
            "País": "EUA",
            "Tipo de Visto": "H2-A Trabalhadores Agricolas",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "Variável",
            "Duração da estadia": "90 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 185.00 € ",
            "Taxa de Serviços": " 100.00 € ",
            "Total": " 285.00 € "
        },
        {
            "País": "EUA",
            "Tipo de Visto": "H2-B Trabalhadores Temporários Não Agricolas",
            "Prazo de emissão": "11 dias",
            "Número de entradas": "Variável",
            "Duração da estadia": "90 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 185.00 € ",
            "Taxa de Serviços": " 100.00 € ",
            "Total": " 285.00 € "
        },
        {
            "País": "EUA",
            "Tipo de Visto": "O - Pessoas Habilidades Extraordinárias",
            "Prazo de emissão": "12 dias",
            "Número de entradas": "Variável",
            "Duração da estadia": "90 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 185.00 € ",
            "Taxa de Serviços": " 100.00 € ",
            "Total": " 285.00 € "
        },
        {
            "País": "EUA",
            "Tipo de Visto": "P - Atletas, Artistas e Entertainers",
            "Prazo de emissão": "13 dias",
            "Número de entradas": "Variável",
            "Duração da estadia": "90 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 185.00 € ",
            "Taxa de Serviços": " 100.00 € ",
            "Total": " 285.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Turismo (Cidadãos UE)",
            "Prazo de emissão": "4-20 dias",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 68.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 158.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Turismo(Cidadãos UE)",
            "Prazo de emissão": "1-3 dias",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 133.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 223.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Turismo (Cidadãos UE)",
            "Prazo de emissão": "4-20 dias",
            "Número de entradas": "2 Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 68.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 158.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Turismo (Cidadãos UE)",
            "Prazo de emissão": "1-3 dias",
            "Número de entradas": "2 entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 133.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 223.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Negócios (Cidadãos UE)",
            "Prazo de emissão": "4-20 dias",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 68.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 158.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Negócios (Cidadãos UE)",
            "Prazo de emissão": "1-3 dias",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 133.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 223.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Negócios (Cidadãos UE)",
            "Prazo de emissão": "4-20 dias",
            "Número de entradas": "2 Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 68.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 158.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Negócios (Cidadãos UE)",
            "Prazo de emissão": "1-3 dias",
            "Número de entradas": "2 entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 133.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 223.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Negócios (Cidadãos UE)",
            "Prazo de emissão": "4-20 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 68.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 158.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Negócios (Cidadãos UE)",
            "Prazo de emissão": "1-3 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 133.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 223.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Entrada Privada (Cidadãos UE)",
            "Prazo de emissão": "4-20 dias",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 68.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 158.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Entrada Privada (Cidadãos UE)",
            "Prazo de emissão": "1-3 dias",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 133.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 223.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Entrada Privada (Cidadãos UE)",
            "Prazo de emissão": "4-20 dias",
            "Número de entradas": "2 Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 68.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 158.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Entrada Privada (Cidadãos UE)",
            "Prazo de emissão": "1-3 dias",
            "Número de entradas": "2 entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 133.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 223.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Estudante (Cidadãos UE)",
            "Prazo de emissão": "4-20 dias",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "Até 90 dias",
            "Validade do visto": "Até 90 dias",
            "Taxas Consulares": " 33.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 123.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Estudante (Cidadãos UE)",
            "Prazo de emissão": "1-3 dias",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "Até 90 dias",
            "Validade do visto": "Até 90 dias",
            "Taxas Consulares": " 133.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 223.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Estudante (Cidadãos UE)",
            "Prazo de emissão": "4-20 dias",
            "Número de entradas": "2 Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 33.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 123.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Estudante (Cidadãos UE)",
            "Prazo de emissão": "1-3 dias",
            "Número de entradas": "2 entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 133.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 223.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Estudante (Cidadãos UE)",
            "Prazo de emissão": "4-20 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 33.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 123.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Estudante (Cidadãos UE)",
            "Prazo de emissão": "1-3 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "Variável",
            "Taxas Consulares": " 133.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 223.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Estudante (Cidadãos UE)",
            "Prazo de emissão": "4-20 dias",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "+ 90 dias",
            "Validade do visto": "+90 dias",
            "Taxas Consulares": " 103.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 193.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Estudante (Cidadãos UE)",
            "Prazo de emissão": "1-3 dias",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "+ 90 dias",
            "Validade do visto": "+ 90 dias",
            "Taxas Consulares": " 203.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 293.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Estudante (Cidadãos UE)",
            "Prazo de emissão": "4-20 dias",
            "Número de entradas": "2 Entradas",
            "Duração da estadia": "+ 90 dias",
            "Validade do visto": "+ 90 dias",
            "Taxas Consulares": " 145.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 235.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Estudante (Cidadãos UE)",
            "Prazo de emissão": "1-3 dias",
            "Número de entradas": "2 entradas",
            "Duração da estadia": "+ 90 dias",
            "Validade do visto": "+ 90 dias",
            "Taxas Consulares": " 287.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 377.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Estudante (Cidadãos UE)",
            "Prazo de emissão": "4-20 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "+ 90 dias",
            "Validade do visto": "+ 90 dias",
            "Taxas Consulares": " 242.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 332.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto Estudante (Cidadãos UE)",
            "Prazo de emissão": "1-3 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "+ 90 dias",
            "Validade do visto": "+ 90 dias",
            "Taxas Consulares": " 481.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 571.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto de Trânsito",
            "Prazo de emissão": "4 - 20 dias",
            "Número de entradas": "1 Entradas",
            "Duração da estadia": "Até 10 dias",
            "Validade do visto": "Até 10 dias",
            "Taxas Consulares": " 68.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 158.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto de Trânsito",
            "Prazo de emissão": "1 - 3 dias",
            "Número de entradas": "1 Entradas",
            "Duração da estadia": "Até 10 dias",
            "Validade do visto": "Até 10 dias",
            "Taxas Consulares": " 133.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 223.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto de Trânsito",
            "Prazo de emissão": "4 - 20 dias",
            "Número de entradas": "2 Entradas",
            "Duração da estadia": "Até 10 dias",
            "Validade do visto": "Até 10 dias",
            "Taxas Consulares": " 68.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 158.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto de Trânsito",
            "Prazo de emissão": "1 - 3 dias",
            "Número de entradas": "2 Entradas",
            "Duração da estadia": "Até 10 dias",
            "Validade do visto": "Até 10 dias",
            "Taxas Consulares": " 133.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 223.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto de Trabalho",
            "Prazo de emissão": "4 - 20 dias",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 103.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 193.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto de Trabalho",
            "Prazo de emissão": "1 - 3 dias",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 103.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 193.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto de Trabalho",
            "Prazo de emissão": "4 - 20 dias",
            "Número de entradas": "2 Entrada",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 145.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 235.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto de Trabalho",
            "Prazo de emissão": "1 - 3 dias",
            "Número de entradas": "2 Entrada",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 287.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 377.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto de Trabalho",
            "Prazo de emissão": "4 - 20 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 242.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 332.00 € "
        },
        {
            "País": "Rússia",
            "Tipo de Visto": "Visto de Trabalho",
            "Prazo de emissão": "1 - 3 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 481.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 571.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Turismo",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "Até 12 meses",
            "Taxas Consulares": " 87.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 177.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Turismo",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "De 1 a 5 anos",
            "Taxas Consulares": " 200.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 290.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Turismo",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "90 dias",
            "Validade do visto": "Até 12 meses",
            "Taxas Consulares": " 87.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 177.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "Até 12 meses",
            "Taxas Consulares": " 103.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 193.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "De 1 a 5 anos",
            "Taxas Consulares": " 212.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 302.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "1 Entradas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "Até 12 meses",
            "Taxas Consulares": " 103.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 193.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Entrada",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "90 dias",
            "Validade do visto": "Até 6 meses",
            "Taxas Consulares": " 70.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 160.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Entrada",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "Até 6 meses",
            "Taxas Consulares": " 70.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 160.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Entrada",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "De 1 a 5 anos",
            "Taxas Consulares": " 200.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 290.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Entrada",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "De 6 a 12 meses",
            "Taxas Consulares": " 103.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 193.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Trabalho",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "Variável",
            "Validade do visto": "Até 6 meses",
            "Taxas Consulares": " 103.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 193.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Trabalho",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "Variável",
            "Validade do visto": "Até 6 meses",
            "Taxas Consulares": " 103.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 193.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Trabalho",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "365 dias",
            "Validade do visto": "De 1 a 5 anos",
            "Taxas Consulares": " 253.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 343.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Trabalho",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "365 dias",
            "Validade do visto": "De 6 a 12 meses",
            "Taxas Consulares": " 170.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 260.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Estudante",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "Variável",
            "Validade do visto": "Até 5 anos",
            "Taxas Consulares": " 70.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 160.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Projeto",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "Variável",
            "Validade do visto": "Até 6 meses",
            "Taxas Consulares": " 103.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 193.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Projeto",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "Variável",
            "Validade do visto": "Até 6 meses",
            "Taxas Consulares": " 103.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 193.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Projeto",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "365 dias",
            "Validade do visto": "De 1 a 5 anos",
            "Taxas Consulares": " 253.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 343.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Projeto",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "365 dias",
            "Validade do visto": "De 6 a 12 meses",
            "Taxas Consulares": " 170.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 260.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Pesquisa",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "90 dias",
            "Validade do visto": "Até 6 meses",
            "Taxas Consulares": " 70.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 160.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Pesquisa",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "Até 6 meses",
            "Taxas Consulares": " 70.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 160.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Pesquisa",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "De 1 a 5 anos",
            "Taxas Consulares": " 200.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 290.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Pesquisa",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "De 6 a 12 meses",
            "Taxas Consulares": " 103.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 193.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Conferência",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "90 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 70.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 160.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Trânsito",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "15 dias",
            "Validade do visto": "15 dias",
            "Taxas Consulares": " 17.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 107.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Trânsito",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "2 Entradas",
            "Duração da estadia": "15 dias",
            "Validade do visto": "15 dias",
            "Taxas Consulares": " 17.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 107.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Tratamento Médico",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "Até 12 meses",
            "Taxas Consulares": " 73.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 163.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Tratamento Médico",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "De 6 a 12 meses",
            "Taxas Consulares": " 103.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 193.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Tratamento Médico",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "90 dias",
            "Validade do visto": "Até 12 meses",
            "Taxas Consulares": " 73.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 163.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Acompanhante Tratamento Médico",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "Até 12 meses",
            "Taxas Consulares": " 73.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 163.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Acompanhante Tratamento Médico",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "90 dias",
            "Validade do visto": "De 6 a 12 meses",
            "Taxas Consulares": " 103.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 193.00 € "
        },
        {
            "País": "Índia",
            "Tipo de Visto": "Visto de Acompanhante Tratamento Médico",
            "Prazo de emissão": "5 dias",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "90 dias",
            "Validade do visto": "Até 12 meses",
            "Taxas Consulares": " 73.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 163.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Trânsito",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "7 dias",
            "Validade do visto": "30 dias",
            "Taxas Consulares": " 30.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 120.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Trânsito",
            "Prazo de emissão": "3 dias (suspenso)",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "7 dias",
            "Validade do visto": "30 dias",
            "Taxas Consulares": " 35.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 125.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Trânsito",
            "Prazo de emissão": "24H (suspenso)",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "7 dias",
            "Validade do visto": "30 dias",
            "Taxas Consulares": " 40.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 130.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Trânsito",
            "Prazo de emissão": "Próprio dia (suspenso)",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "7 dias",
            "Validade do visto": "30 dias",
            "Taxas Consulares": " 80.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 170.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Turismo",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "60 dias",
            "Taxas Consulares": " 60.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 150.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Turismo",
            "Prazo de emissão": "3 dias (suspenso)",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "60 dias",
            "Taxas Consulares": " 80.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 170.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Turismo",
            "Prazo de emissão": "24H (suspenso)",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "60 dias",
            "Taxas Consulares": " 90.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 180.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Turismo",
            "Prazo de emissão": "Próprio dia (suspenso)",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "60 dias",
            "Taxas Consulares": " 160.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 250.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "60 dias",
            "Taxas Consulares": " 60.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 150.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "3 dias (suspenso)",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "60 dias",
            "Taxas Consulares": " 80.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 170.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "24H (suspenso)",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "60 dias",
            "Taxas Consulares": " 90.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 180.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "Próprio dia (suspenso)",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "60 dias",
            "Taxas Consulares": " 160.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 250.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Turismo",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 165.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 255.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Turismo",
            "Prazo de emissão": "3 dias (suspenso)",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 210.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 300.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Turismo",
            "Prazo de emissão": "24H (suspenso)",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 240.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 330.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Turismo",
            "Prazo de emissão": "Próprio dia (suspenso)",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 440.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 530.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 165.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 255.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "3 dias (suspenso)",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 210.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 300.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "24H (suspenso)",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 240.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 330.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Negócios",
            "Prazo de emissão": "Próprio dia (suspenso)",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 440.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 530.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Visitante",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "60 dias",
            "Taxas Consulares": " 60.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 150.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Visitante",
            "Prazo de emissão": "3 dias (suspenso)",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "60 dias",
            "Taxas Consulares": " 80.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 170.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Visitante",
            "Prazo de emissão": "24H (suspenso)",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "60 dias",
            "Taxas Consulares": " 90.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 180.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Visitante",
            "Prazo de emissão": "Próprio dia (suspenso)",
            "Número de entradas": "1 Entrada",
            "Duração da estadia": "30 dias",
            "Validade do visto": "60 dias",
            "Taxas Consulares": " 160.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 250.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Visitante",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 165.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 255.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Visitante",
            "Prazo de emissão": "3 dias (suspenso)",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 210.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 300.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Visitante",
            "Prazo de emissão": "24H (suspenso)",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 240.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 330.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Visitante",
            "Prazo de emissão": "Próprio dia (suspenso)",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "90 dias",
            "Taxas Consulares": " 440.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 530.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Trabalho",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "Múltiplas Entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "60 dias",
            "Taxas Consulares": " 330.00 € ",
            "Taxa de Serviços": " 120.00 € ",
            "Total": " 450.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Estudante",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "365 dias",
            "Validade do visto": "12 meses",
            "Taxas Consulares": " 120.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 210.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Residência",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "30 dias",
            "Validade do visto": "60 dias",
            "Taxas Consulares": " 120.00 € ",
            "Taxa de Serviços": " 90.00 € ",
            "Total": " 210.00 € "
        },
        {
            "País": "Moçambique",
            "Tipo de Visto": "Visto de Permanência Temporária",
            "Prazo de emissão": "10 dias",
            "Número de entradas": "Múltiplas entradas",
            "Duração da estadia": "365 dias",
            "Validade do visto": "12 meses",
            "Taxas Consulares": " 330.00 € ",
            "Taxa de Serviços": " 120.00 € ",
            "Total": " 450.00 € "
        }
    ]
}

export default pricedata