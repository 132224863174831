import React, { useContext } from 'react'
import { useNavigate } from "react-router-dom";
import Header from './Header'
import AuthContext from "../Hooks/userContext";
import { Alert } from 'react-bootstrap'
import { useState } from 'react';
import axios from "axios";
import { AiOutlineRollback } from "react-icons/ai";


export default function Login() { 
    
    const [error, setError] = useState("");
    const { setUserContext } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [registo, setRegisto] = useState({
        nome:"",
        sobrenome:"",
        email:"",
        username:"",
        password:"",
        password2:""
    })

    const navigate = useNavigate();

    //Save registo data
    function handleRegisto(e){
        e.preventDefault()

        const value = e.target.value;
        setRegisto({
            ...registo,
            [e.target.name]:value
        });
    }

    const back = () => {
        navigate("/");
      };

    function userValidation(){

        if(registo.password !== registo.password2){ 
            setError("Passwords não são iguais")
            return false
        }

        try {
            axios.post("/api/usercheck",{username:registo.username}).then(res =>{
                if(res.data.message==="user exists"){
                    setError("Este username já existe")
                }
            })
          } catch (err) {
            setError(err);
          }

          try {
            axios.post("/api/useremailcheck",{email:registo.email}).then(res =>{
                if(res.data.message==="user email exists"){
                    setError("Este email já está a ser usado")
                }
            })
          } catch (err) {
            setError(err);
          }

    }

    //POST Registo
    async function submitRegisto (e){
        e.preventDefault();
        setError("")
        userValidation()

        
        setLoading(true)

        
        try {
            var headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');
            
            const requestOptions = {
            method: "POST",
            credentials: 'include', // Don't forget to specify this if you need cookies
            headers: headers,
            redirect: 'follow',
            body: JSON.stringify({nome:registo.nome, sobrenome:registo.sobrenome, password:registo.password, email:registo.email, username:registo.username})
            };
    
            await fetch('/api/register', requestOptions)
            .then((response) => {
                if (response.status >= 400 && response.status < 600) {
                    throw new Error("Bad response from server");
                }
                setUserContext()
                navigate('/pedido')
                return response;
            })
            .then(response => console.log(response.json()))
            
        } catch (err) {
            setError("Não foi possivel criar a sua conta")
        }
        
        setLoading(false)
    }

    return (
        <div>
            <Header></Header>
            <div className="container-login100">
                <div className="container-bg"></div>
                <div className="container-login">
                    <div className="wrap-login100">
                        <form className="register100-form validate-form" onSubmit={submitRegisto}>
                            <span className="login100-form-title">
                                Bem Vindo!
                            </span>

                            <div className="wrap-input100 validate-input" data-validate = "Valid email is required">
                                <input className="input100" type="text" name="nome" value={registo.nome} placeholder="Nome" onChange={handleRegisto} required/>
                                <span className="focus-input100"></span>
                                <input className="input100" type="text" name="sobrenome" value={registo.sobrenome} placeholder="Apelido" onChange={handleRegisto} required/>
                                <span className="focus-input100"></span>
                            </div>

                            <div className="wrap-input100 validate-input" data-validate = "Valid email is required">
                                <input className="input100" type="email" name="email" value={registo.email} placeholder="Email" onChange={handleRegisto} required/>
                                <span className="focus-input100"></span>
                            </div>

                            <div className="wrap-input100 validate-input" data-validate = "Valid email is required">
                                <input className="input100" type="text" name="username" value={registo.username} placeholder="Username" onChange={handleRegisto} required/>
                                <span className="focus-input100"></span>
                            </div>

                            <div className="wrap-input100 validate-input" data-validate = "Password is required">
                                <input className="input100" type="password" name="password" value={registo.password} placeholder="Password" onChange={handleRegisto} required/>
                                <span className="focus-input100"></span>

                                <input className="input100" type="password" name="password2" value={registo.password2} placeholder="Confirme Password" onChange={handleRegisto} required/>
                                <span className="focus-input100"></span>
                            </div>

                            {error && <Alert variant="danger">{error}</Alert>}
                            
                            <div className="container-login100-form-btn login">
                                <button disabled={loading} className="login100-form-btn" type="submit">
                                    Registar
                                </button>
                                <button className="guest-btn" onClick={back}>
                                    <p>Voltar ao Login <AiOutlineRollback/></p>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
