import  {Modal,Button}  from 'react-bootstrap';
import React, { useEffect, useState } from "react"



export default function Client_Modal(props,docs) {

    //const [update, setUpdate] = useState(false)
    const [data, setData] = useState({})
    const [documents,setDocuments]=useState()
    
    

useEffect(() => {

  
  setData({...props.clientData})
  
  //setUpdate(false);

}, [props.clientData], )

console.log(props.docs)

return (
      <Modal
        size="lg"
        show={props.value}
        aria-labelledby="example-modal-sizes-title-lg"
      >

      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg" className="modal-title">
          Detalhes do Cliente
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
      <div className="form-row ml-1 clientmodal">
          <div className="row">
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Nome</label>
                  <input type="text" className="form-control"  name="nome" value={data.nome} ></input>
              </div>
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Apelido</label>
                  <input type="text" className="form-control"  name="apelido" value={data.apelido} ></input>
              </div>
          </div>
          <div className="row">
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Email</label>
                  <input className="form-control"  type="email" name="email" value={data.email}></input>
              </div>
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Telefone</label>
                  <input type="number" className="form-control"  name="telefone" value={data.telefone}></input>
              </div>
          </div>
          <div className="row">
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Nacionalidade</label>
                  <input className="form-control"  type="text" name="nacionalidade" minLength="4" value={data.nacionalidade}></input>
              </div>
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Género</label>
                  <input type="text" className="form-control" value={data.genero} ></input>
              </div>
          </div>
          <div className="row">
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Data de Nascimento</label>
                  <input type="text" className="form-control"  name="num_pass" value={data.data_nascimento} ></input>
              </div>

              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Local de Nascimento</label>
                  <input className="form-control" type="text" name="local_nascimento" value={data.local_nascimento} minLength="4"></input>
              </div>
          </div>
          <div className="row">
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Data da Viagem</label>
                  <input type="text" className="form-control"  name="num_pass" value={data.data_viagem} ></input>
              </div>

              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Data da Saída</label>
                  <input type="text" className="form-control"  name="num_pass" value={data.data_saida} ></input>
              </div>
          </div>
          <div className="row">
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Nº Passaporte</label>
                  <input type="number" className="form-control"  name="num_pass" value={data.num_pass} ></input>
              </div>
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Data de Emissão</label>
                  <input type="text" className="form-control"  name="num_pass" value={data.data_emissao} ></input>
              </div>
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Data de Validade</label>
                  <input type="text" className="form-control"  name="num_pass" value={data.data_validade} ></input>
              </div>
          </div>
          <div className="row">
              <div className="col-sm">
                  <label>Países visitados nos últimos 5 anos</label>
                  <input type="text" className="form-control" id="formGroupExampleInput" name="paises_visitados" value={data.paises_visitados}></input>
              </div>
          </div>
          <div className="row">
              <div className="col-sm">
                  <label>Já obteve visto para o país? Se sim, quando e qual o número do visto?</label>
                  <input type="text" className="form-control" id="formGroupExampleInput" name="visto_pais" value={data.visto_pais}></input>
              </div>
          </div>
          <div className="row">
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Morada de residência</label>
                  <input type="text" className="form-control"  name="morada" value={data.morada} ></input>
              </div>
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Contato de emergência</label>
                  <input type="number" className="form-control"  name="emergencia" value={data.contacto}></input>
              </div>
          </div>
          <div className="row">
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Local de estadia</label>
                  <input type="text" className="form-control"  name="morada" value={data.estadia} ></input>
              </div>
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Nome pessoa ou organização responsável</label>
                  <input type="text" className="form-control"  name="emergencia" value={data.responsavel}></input>
              </div>
          </div>
          <div className="row">
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Email país destino</label>
                  <input type="text" className="form-control"  name="morada" value={data.email_destino} ></input>
              </div>
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Telefone país destino</label>
                  <input type="number" className="form-control"  name="emergencia" value={data.telefone_destino}></input>
              </div>
          </div>
          <hr className="dashed"></hr>

          <div className="row">
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Pais de Destino</label>
                  <input type="text" className="form-control" value={data.pais} ></input>
              </div>
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Tipo de Visto</label>
                  <input type="text" className="form-control" value={data.tipo_visto}></input>
              </div>
          </div>

          <div className="row">
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Prazo de Emissão</label>
                  <input type="text" className="form-control"  name="morada" value={data.prazo_emissao} ></input>
              </div>
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Número de entradas</label>
                  <input type="text" className="form-control"  name="emergencia" value={data.num_entradas}></input>
              </div>
          </div>

          <div className="row">
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Duração da estadia</label>
                  <input type="text" className="form-control"  name="morada" value={data.duracao_estadia} ></input>
              </div>
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Validade do visto</label>
                  <input type="text" className="form-control"  name="emergencia" value={data.validade_visto}></input>
              </div>
          </div>

          <div className="row">
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Preço</label>
                  <input type="text" className="form-control"  value={data.preço} ></input>
              </div>
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Pagamento Efetuado</label>
                  <input type="text" className="form-control"  name="emergencia" value={data.estado}></input>
              </div>
          </div>

          <hr className="dashed"></hr>

          <div className="row">
              <div className="col-sm">
                  <label htmlFor="formGroupExampleInput">Uploads</label>
                  <br></br>
                  {props.docs.map((doc,iter) => {
                        let path="uploads/"+doc
                        return(
                        <p key={iter}><a href={path} download>{doc}</a></p>
                  )} )}
                
              </div>
          </div>

      </div>

      </Modal.Body>

      <Modal.Footer>
         <Button className='btn-clientmodal' onClick={props.toggle}>
          Fechar
        </Button>
      </Modal.Footer>
      </Modal>

    );
}