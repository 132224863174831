import {BrowserRouter, Routes, Route} from "react-router-dom";
import Login from "./Components/Login";
import Registo from "./Components/Registo";
import MainStep from "./Components/MainStep";
import Sucess from "./Components/Sucess"
import Forgot from "./Components/Forgot";
import Failed from "./Components/Failed"
import Search from "./Components/Search"
import AuthProvider from './Context/AuthContext'
import PrivateRoute from "./Components/PrivateRoute";


function App() {

  return (
    <AuthProvider>
      <BrowserRouter>
          <div className="App">
            <Routes>
              <Route path="/" element={<Login/>}/>
              <Route path="/registo" element={<Registo/>}/>
              <Route path="/forgot" element={<Forgot/>}/>
              <Route path='/pedido' element={<PrivateRoute><MainStep/></PrivateRoute>}/>
              <Route path='/sucess' element={<PrivateRoute><Sucess/></PrivateRoute>}/>
              <Route path='/fail' element={<PrivateRoute><Failed/></PrivateRoute>}/>
              <Route path="/search" element={<PrivateRoute><Search/></PrivateRoute>}/>
            </Routes>
          </div>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
