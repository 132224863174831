import React, { useContext } from "react";
import AuthContext from "../Hooks/userContext";
import { useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Header from "./Header";
import { v4 as uuidv4 } from "uuid";
import axios from 'axios';

export default function MainStep() {
  console.log("Mainstep");
  const [state, setState] = useState({
    step: 1,
  });

  const { currentUser } = useContext(AuthContext);

  const [Step1_Data, setStep1_Data] = useState("");

  const [step1Aux, setstep1Aux] = useState("");

  const [Data, setData] = useState([
    {
      nome: "",
      apelido: "",
      email: "",
      telefone: "",
      nacionalidade: "",
      genero: "",
      data_nascimento: "",
      local_nascimento: "",
      data_viagem: "",
      data_saida: "",
      num_pass: "",
      data_emissao: "",
      data_validade: "",
      paises_visitados: "",
      visto_pais: "",
      morada: "",
      emergencia: "",
      estadia: "",
      responsavel: "",
      email_destino: "",
      telefone_destino: "",
    },
  ]);

  const [selectedFile, setSelectedFile] = useState();

  const handleStep1Data = (values, auxVal) => {
    setStep1_Data(values);
    setstep1Aux(auxVal);
  };

  const handleChange = (date, index, e) => {
    let newArr = [...Data];
    //console.log(date.target.value,index,e)
    
    if (date === "") {
      newArr[index][e.target.name] = e.target.value;
      setData(newArr);
    } else {
      console.log(Data);
      newArr[e][index] = date.target.value;
      setData(newArr);
    }
  };

  const handleNewfield = (data) => {
    setData(data);
  };

  const handleDate = (date, e, index) => {
    // let newArr = [...Data];
    // newArr[index][e] = date;
    // setData(newArr);

    console.log(Data);
  };

  const handleUpload =(data) =>{
    setSelectedFile(data)
  }


  const submitPayment = () => {
    const id_visto = uuidv4();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ 
        query: Step1_Data, 
        id_visto: id_visto }),
    };
    const formData = new FormData();
    formData.append('id_visto',id_visto)
        
    for (var doc=0;doc<selectedFile.length;doc++){
        let docname="file"+doc
        formData.append(docname, selectedFile[doc]);
    }
    
    axios.post("/api/upload_docs", formData)
      .then((response) => {
        console.log(response);
      })
      .catch((err) => console.log("error"));

    fetch("/api/dados_visto", requestOptions)
      .then((response) => {
        console.log(response);
      })
      .catch((err) => console.log("error"));

    const requestOptions_details = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query: Data,
        id_visto: id_visto,
        username: currentUser,
      }),
    };

    fetch("/api/dados_clientes", requestOptions_details)
      .then((response) => {
        console.log(response);
      })
      .catch((err) => console.log("error"));

    const requestOptions_payment = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query: Step1_Data,
        id_visto: id_visto,
        number: Data.length,
      }),
    };

    fetch("/api/create-payment-intent", requestOptions_payment)
      .then((response) => response.json())
      .then((data) => {
        window.location = data.url;
      })
      .catch((err) => console.log(err));
  };

  const prevStep = () => {
    const { step } = state;
    setState({ step: step - 1 });
  };
  const nextStep = () => {
    const { step } = state;
    setState({ step: step + 1 });
  };

  const formState = () => {
    switch (state.step) {
      case 1:
        return (
          <Step1
            nextStep={nextStep}
            handleStep1Data={handleStep1Data}
            Step1_Data={Step1_Data}
            step1Aux={step1Aux}
          />
        );

      case 2:
        return (
          <Step2
            prevStep={prevStep}
            nextStep={nextStep}
            handleChange={handleChange}
            handleDate={handleDate}
            handleNewfield={handleNewfield}
            values={Data}
          />
        );

      case 3:
        return (
          <Step3
            prevStep={prevStep}
            values={Data}
            tripData={Step1_Data}
            submitPayment={submitPayment}
            handleUpload={handleUpload}
          />
        );

      default:
        return <></>;
    }
  };
  return (
    <div>
      <Header></Header>
      {formState()}
    </div>
  );
}
