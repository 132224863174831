import React, { useEffect } from 'react'
import { motion } from 'framer-motion';
import { useState} from 'react';
import pricedata from '../Price/data.js'

export default function Step1({nextStep, handleStep1Data, Step1_Data, step1Aux}) {

    const [dataStep1, setStep1] = useState({
        pais:"",
        tipo_visto:"",
        prazo_emissao:"",
        num_entradas:"",
        duracao_estadia:"",
        validade_visto:"",
        preço:""
    })

    const [auxData, setauxData] = useState({
        visto_modelo:[],
        aux_emissao:[],
        aux_entradas:[],
        aux_estadia:[],
        aux_validade:[]
    })

    useEffect(() => {

        if(step1Aux.length!==0) {
                     Object.keys(step1Aux).map((data) => {
             return setauxData({...step1Aux,data:step1Aux[data]})
         })
        }
      },[step1Aux]);
              
    const pais_modelo =["Angola", "Arábia Saudita", "Argélia", "Brasil", "Cazaquistão", "China",
    "Congo Brazzaville", "Gabão", "Guiné Equatorial", "Moçambique", "RDC", "Senegal", "EUA", "Rússia", "Índia"]
    
    const handleField = (e) =>{
        
        //Save selection
        setStep1({
            ...dataStep1,
            [e.target.name]: e.target.value
          });
          console.log(dataStep1)
        const auxval = []      

        handleNewField(e.target.name)

        // Read JSON DATA
        pricedata.Preços.forEach(function (row) {
            if(e.target.name==="pais"){
                if (row["País"]===e.target.value){
                    auxval.push(row["Tipo de Visto"])
                }
            }

            else if(e.target.name==="tipo_visto"){
                if (row["Tipo de Visto"]===e.target.value && row["País"]===dataStep1.pais){
                    auxval.push(row["Prazo de emissão"])
                }
            }

            else if(e.target.name==="prazo_emissao"){
                if (row["Prazo de emissão"]===e.target.value && row["País"]===dataStep1.pais && row["Tipo de Visto"]===dataStep1.tipo_visto){
                    auxval.push(row["Número de entradas"])
                }
            }

            else if(e.target.name==="num_entradas"){
                if (row["Número de entradas"]===e.target.value && row["País"]===dataStep1.pais && row["Tipo de Visto"]===dataStep1.tipo_visto
                && row["Prazo de emissão"]===dataStep1.prazo_emissao){
                    auxval.push(row["Duração da estadia"])
                }
            }

            else if(e.target.name==="duracao_estadia"){
                if (row["Duração da estadia"]===e.target.value && row["País"]===dataStep1.pais && row["Tipo de Visto"]===dataStep1.tipo_visto
                && row["Prazo de emissão"]===dataStep1.prazo_emissao && row["Número de entradas"]===dataStep1.num_entradas){
                    auxval.push(row["Validade do visto"])
                }
            }

            // UPDATE PRICE VALUE
            else if(e.target.name==="validade_visto"){   
                 
                if (row["Validade do visto"]===e.target.value && row["País"]===dataStep1.pais && row["Tipo de Visto"]===dataStep1.tipo_visto
                && row["Prazo de emissão"]===dataStep1.prazo_emissao && row["Número de entradas"]===dataStep1.num_entradas 
                && row["Duração da estadia"]===dataStep1.duracao_estadia){
                    setStep1({
                        ...dataStep1,
                        validade_visto:e.target.value, 
                        preço: row["Total"]
                        });
                }
            }
        });
        
        let uniqueSet = [...new Set(auxval)];

        if(e.target.name==="pais"){
            setauxData({
        ...auxData,
        "visto_modelo": uniqueSet
        });
        }

        else if(e.target.name==="tipo_visto"){
            setauxData({
        ...auxData,
        "aux_emissao": uniqueSet
        });
        }

        else if(e.target.name==="prazo_emissao"){
            setauxData({
        ...auxData,
        "aux_entradas": uniqueSet
        });
        }

        else if(e.target.name==="num_entradas"){
            setauxData({
        ...auxData,
        "aux_estadia": uniqueSet
        });
        }

        else if(e.target.name==="duracao_estadia"){
            setauxData({
        ...auxData,
        "aux_validade": uniqueSet
        });
        }
    }


    function handleNewField(fieldchange){
        (fieldchange==="pais") ? setauxData({visto_modelo: [],aux_emissao:[],aux_entradas:[], aux_estadia:[], aux_validade:[]}) :
        (fieldchange==="tipo_visto") ? setauxData({aux_emissao:[],aux_entradas:[], aux_estadia:[], aux_validade:[]}) :
        (fieldchange==="prazo_emissao") ? setauxData({aux_entradas:[], aux_estadia:[], aux_validade:[]}) : 
        (fieldchange==="num_entradas") ? setauxData({ aux_estadia:[], aux_validade:[]}) :
        (fieldchange==="duracao_estadia") ? setauxData({ aux_validade:[]}) : console.log("no change")

    }

    const Continue = (e) =>{
        const isNull = Object.values(dataStep1).every(value => {
            if (value.length === 0) {
                console.log("teste1")
                return false;
              
            }
            console.log(value)
            return true;
          });

        if(isNull === false){
            alert("Preencha todos os campos")
            return;
        }
        e.preventDefault();
        handleStep1Data(dataStep1, auxData)
        nextStep(dataStep1);
    }
   

    return (
        <div>
            <section className="multi_step_form">
                <div className="msform">
                    <div className="tittle">
                    <h2>Pedido de Visto</h2>
                    </div>

                    <ul id="progressbar">
                        <li className="active">Dados Iniciais</li>
                        <li>Visto</li>
                        <li>Pagamento</li>
                    </ul>
                    <motion.div
                    className="col-md-6 offset-md-3"
                    initial={{ x: '-100vw' }}
                    animate={{ x: 0 }}
                    transition={{     type: 'tween',
                    duration: 0.7 }}>
                        <div className='form-row'>
                            <div className="row">
                                <div className="col-sm">
                                    <label>Pais de destino</label>

                                    <select className="form-control" name="pais" defaultValue={Step1_Data.pais} onChange={handleField} required>
                                    <option>Selecione</option>
                                        {pais_modelo.map((y,x) => {
                                            return(<option key={x}>{y}</option>)
                                        } )
                                        }
                                    </select>

                                </div>
                                <div className="col-sm">
                                    <label>Tipo de visto</label>
                                    <select className="form-control" name='tipo_visto' defaultValue={Step1_Data.tipo_visto} onChange={handleField} required>
                                        <option>Selecione</option>
                                        {auxData.visto_modelo.map((y,x) => {
                                            return(<option key={x} value={y}>{y}</option>)
                                        } )
                                    }
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <label>Prazo de emissão</label>
                                    <select className="form-control" name="prazo_emissao" value={Step1_Data.prazo_emissao} onChange={handleField} required>
                                    <option>Selecione</option>
                                        {auxData.aux_emissao.map((y,x) => {
                                            return(<option key={x} value={y}>{y}</option>)
                                        } )}
                                    </select>

                                </div>
                                <div className="col-sm">
                                    <label>Número de entradas</label>
                                        
                                    <select className="form-control" name="num_entradas" value={Step1_Data.num_entradas} onChange={handleField} required>
                                    <option>Selecione</option>
                                        {auxData.aux_entradas.map((y,x) => {
                                            return(<option key={x} value={y}>{y}</option>)
                                        } )}
                                    </select>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <label>Duração da estadia</label>
                                    <select className="form-control" name="duracao_estadia" value={Step1_Data.duracao_estadia} onChange={handleField} required>
                                    <option>Selecione</option>
                                        {auxData.aux_estadia.map((y,x) => {
                                            return(<option key={x} value={y}>{y}</option>)
                                        } )}
                                    </select>
                                </div>
                                <div className="col-sm">
                                    <label>Validade do visto</label>
                                    <select className="form-control" name="validade_visto" value={Step1_Data.validade_visto} onChange={handleField} required>
                                        <option>Selecione</option>
                                        {auxData.aux_validade.map((y,x) => {
                                            return(<option key={x} value={y}>{y}</option>)
                                        } )}
                                    </select>    
                                 </div>
                            </div>
                        </div>
                    </motion.div>

                    <button type="button" className="next action-button" onClick={Continue}>Continuar</button>            
                </div>
            </section>
        </div>
    )
}
