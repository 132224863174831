import Header from './Header'


export default function Sucess() {
   
    return (
        
        <div>
            <Header></Header>
            <div className="card">
                <div className="icon">
                    <i className='checkmark'>✓</i>
                </div>
                <h1>Sucesso</h1> 
                <p className='description-sucess'>Obrigado por ter efetuado o pagamento<br/> iremos entrar em contacto consigo nos próximos dias</p>
                <button className="login100-form-btn"><a href="https://sofiamachado.pt/">Página Inicial</a></button>
        </div>
        </div>
    )
}
