import React from "react";
import { useContext } from "react";
import AuthContext from "../Hooks/userContext";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const { user } = useContext(AuthContext);
  const { logout } = useContext(AuthContext);

  const navigate = useNavigate();

  function handleLogout(event) {
    event.preventDefault();
    try {
      var headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Accept", "application/json");

      const requestOptions = {
        method: "GET",
        headers: headers,
      };

      fetch("/api/logout", requestOptions)
        .then((response) => {
          console.log(response.json());
        })
        .catch((error) => console.log(error));
      logout();
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <header>
      <a href="https://sofiamachado.pt/">
        <div className="logo"></div>
      </a>
      {user ? (
        <nav className="nav-layout">
          <NavLink to="" onClick={handleLogout} className="nav-header">
            <div className="d-flex w-100 justify-content-start align-items-center">
              <span>Logout</span>
            </div>
          </NavLink>
        </nav>
      ) : (
        <div></div>
      )}
    </header>
  );
}
